import { FC } from 'react';

import { DefaultThemeProvider } from 'theme';

import { Title } from '../Panel/Panel.styled';

import { Children, Root } from './PanelHeader.styled';
import { IPanelHeaderProps } from './types';

/**
 * This component is deprecated.
 * @deprecated
 */
export const PanelHeader: FC<IPanelHeaderProps> = ({
  children,
  className = '',
  title,
  action,
}) => {
  return (
    <DefaultThemeProvider>
      <Root className={className}>
        <Title>
          <h1>{title}</h1>
          {action && action}
        </Title>
        {children && <Children>{children}</Children>}
      </Root>
    </DefaultThemeProvider>
  );
};
