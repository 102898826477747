import { useCallback, useMemo, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import {
  deleteEvents,
  executeEvents,
  rollbackEvents,
  uploadEventsFile,
} from 'services/CorporateActions';
import { EvenStatuses } from 'services/types';

export function useFiles(reloadData: VoidFunction) {
  const [fileIsLoading, setFileIsLoading] = useState(false);
  const [blobFile, setBlobFile] = useState<Blob | null>(null);
  const [inputFileKey, setInputFileKey] = useState('');

  const handleConfirmUploadFile = async () => {
    if (!blobFile) {
      return;
    }
    try {
      setFileIsLoading(true);
      await uploadEventsFile(blobFile);
      reloadData();
      Notification.success({ title: 'File was uploaded' });
    } catch (error) {
      Notification.error({ title: 'Upload file error' });
    } finally {
      setFileIsLoading(false);
      setBlobFile(null);
    }
  };

  const handleUploadFile = (file: Blob) => {
    setBlobFile(file);
    setInputFileKey(Math.random().toString());
  };
  const handleCloseConfirmModal = () => {
    setBlobFile(null);
  };

  const blobFileIsExist = useMemo(() => !!blobFile, [blobFile]);
  // TODO: Take file name
  const fileName = useMemo(() => 'file', [blobFile]);

  return {
    fileName,
    fileIsLoading,
    handleUploadFile,
    handleConfirmUploadFile,
    handleCloseConfirmModal,
    blobFileIsExist,
    inputFileKey,
  };
}

type ActionType = 'delete' | 'execute' | 'rerun' | 'rollback' | '';
export function useSelectedRows(reloadData: VoidFunction) {
  const [actionType, setActionType] = useState<ActionType>('');
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [executeIsLoading, setExecuteIsLoading] = useState(false);
  const [reRunIsLoading, setReRunIsLoading] = useState(false);
  const [rollbackIsLoading, setRollbackIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const handleSelectRow = useCallback(
    (value: any) => {
      setSelectedRows((prev) => {
        if (Array.isArray(value)) {
          return value.map((item) => item);
        }

        if (prev.includes(value)) {
          return prev.filter((row) => row !== value);
        }

        return [...prev, value];
      });
    },
    [setSelectedRows],
  );

  const ids = useMemo(
    () => selectedRows.map((item) => item.original.id),
    [selectedRows],
  );

  const handleDeleteSelectedIds = async () => {
    try {
      setDeleteIsLoading(true);
      await deleteEvents(ids);
    } catch (error) {
      Notification.error({ title: 'Delete events error' });
    } finally {
      reloadData();
      setDeleteIsLoading(false);
      setSelectedRows([]);
    }
  };

  const handleExecuteSelectedIds = async () => {
    try {
      setExecuteIsLoading(true);
      await executeEvents(ids);
    } catch (error) {
      Notification.error({ title: 'Execute events error' });
    } finally {
      reloadData();
      setExecuteIsLoading(false);
      setSelectedRows([]);
    }
  };

  const handleRollbackSelectedIds = async () => {
    try {
      setRollbackIsLoading(true);
      await rollbackEvents(ids);
    } catch (error) {
      Notification.error({ title: 'Rollback events error' });
    } finally {
      reloadData();
      setRollbackIsLoading(false);
      setSelectedRows([]);
    }
  };

  const handleReRunSelectedIds = async () => {
    try {
      setReRunIsLoading(true);
      await executeEvents(ids, true);
    } catch (error) {
      Notification.error({ title: 'Rerun events error' });
    } finally {
      reloadData();
      setReRunIsLoading(false);
      setSelectedRows([]);
    }
  };

  const handleConfirmAction = () => {
    if (actionType === 'delete') {
      handleDeleteSelectedIds();
    }
    if (actionType === 'execute') {
      handleExecuteSelectedIds();
    }
    if (actionType === 'rerun') {
      handleReRunSelectedIds();
    }
    if (actionType === 'rollback') {
      handleRollbackSelectedIds();
    }
    setActionType('');
  };

  const rollbackIsDisabled = useMemo(
    () =>
      !!selectedRows.length &&
      !selectedRows?.every((item) =>
        [
          EvenStatuses.DONE,
          EvenStatuses.AUTO_DONE,
          EvenStatuses.UPCOMING,
        ].includes(item.original.status),
      ),
    [selectedRows],
  );

  const rollbackButtonTitle = useMemo(
    () =>
      rollbackIsDisabled
        ? 'You can execute only events with statuses: Done, Auto Done'
        : 'To rollback events',
    [rollbackIsDisabled],
  );

  const executeIsDisabled = useMemo(
    () =>
      !selectedRows.length ||
      selectedRows?.reduce(
        (acc, item) =>
          acc ||
          item.original.status === EvenStatuses.DELETED ||
          item.original.is_processed,
        false,
      ),
    [selectedRows],
  );
  const executeButtonTitle = useMemo(
    () =>
      executeIsDisabled
        ? 'You can rerun only not deleted and not processed events'
        : 'To manually post or calculate Upcoming events',
    [executeIsDisabled],
  );
  const deleteIsDisabled = useMemo(
    () =>
      !selectedRows?.every((item) =>
        [
          EvenStatuses.NO_CLIENTS,
          EvenStatuses.PENDING,
          EvenStatuses.MANUAL,
          EvenStatuses.EVENT_ERROR,
          EvenStatuses.UPCOMING,
        ].includes(item.original.status),
      ),
    [selectedRows],
  );
  const deleteButtonTitle = useMemo(
    () =>
      deleteIsDisabled
        ? 'You can`t delete events with statuses: Done'
        : 'To delete incorrect Upcoming events',
    [deleteIsDisabled],
  );
  const rerunIsDisabled = useMemo(
    () =>
      !selectedRows.length ||
      selectedRows?.reduce(
        (acc, item) =>
          acc ||
          item.original.status === EvenStatuses.DELETED ||
          !item.original.is_processed,
        false,
      ),
    [selectedRows],
  );
  const rerunButtonTitle = useMemo(
    () =>
      rerunIsDisabled
        ? 'You can rerun only not deleted and processed events'
        : 'To rerun the script for posted or incompletely posted Events',
    [rerunIsDisabled],
  );

  return {
    selectedRows,
    setSelectedRows,
    rollbackIsDisabled,
    rollbackButtonTitle,
    executeIsDisabled,
    executeButtonTitle,
    deleteIsDisabled,
    deleteButtonTitle,
    rerunIsDisabled,
    rerunButtonTitle,
    handleSelectRow,
    deleteIsLoading,
    executeIsLoading,
    rollbackIsLoading,
    reRunIsLoading,
    actionType,
    setActionType,
    handleConfirmAction,
  };
}
