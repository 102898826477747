import { ReactElement } from 'react';

import { DefaultThemeProvider } from 'theme';

import { StyledTabs } from './styled';
import { IUITabsProps } from './types';

export const Tabs = <Value = any,>({
  children,
  className,
  value,
  onChange,
  ...rest
}: IUITabsProps<Value>): ReactElement => {
  return (
    <DefaultThemeProvider>
      <StyledTabs
        className={className}
        value={value}
        onChange={onChange}
        {...rest}
      >
        {children}
      </StyledTabs>
    </DefaultThemeProvider>
  );
};
