import { css } from '@mui/material/styles';

import { styled } from 'theme';

const baseStyles = css`
  background: white;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Root = styled('div')`
  ${baseStyles}
  h1 {
    color: ${({ theme }) => theme?.color.typo.primary};
    font: ${({ theme }) => `32px ${theme?.font.header}`};
    margin: 0;
  }
`;

export const Children = styled('div')`
  flex-shrink: 0;
  margin-left: 24px;
`;
